import {
    bin,
    max, select
} from "d3";
import { Engine, World, Bodies, Body } from "matter-js";

/**
 * 
 * @param {Event} e 
 * @param {Array} d 
 */
export function showTooltip(e, d, tooltipDiv, isComparisonChart) {

    const partyname = d.isSuspended ? `Suspended ${d.name}` : d.name;
    const seats = d.isSuspended ? d.suspendedSeats : d.seats - d.suspendedSeats;
    const role = d.role;
    const comparisonSeats = d.comparisonSeats;
    const comparisonText = comparisonSeats == undefined || NaN ? `(0)` : comparisonSeats == 0 ? `(=)` : comparisonSeats > 0 ? `(+${comparisonSeats})` : `(${comparisonSeats})`;

    tooltipDiv.transition()
        .duration(200)
        .style("opacity", 1);

    let tooltipContent;

    if (d.ordering < 0) {
        tooltipContent = "<b>" + role + "</b>" + (d.tooltipText.length > 0 ? "<br><br>" + d.tooltipText : "");
    } else {
        if (isComparisonChart) {
            const seatsText = `<span style="display: flex;justify-content: space-between;"><span>${partyname}</span><span style="margin-left:20px">${seats} ${comparisonText}</span></span>`;

            tooltipContent = d.tooltipText.length > 0
                ? "<b>" + seatsText + "</b>" + "<br>" + role + "<br><br>" + d.tooltipText
                : "<b>" + seatsText + "</b>" + "<br>" + role;
        }
        else {
            const seatsText = "<b>" + `<span style="display: flex;justify-content: space-between;"><span>${partyname}</span><span style="margin-left:20px">${seats}</span></span>` + "</b>";

            tooltipContent = d.tooltipText.length > 0
                ? seatsText + "<br>" + role + "<br><br>" + d.tooltipText
                : seatsText + "</b>" + "<br>" + role;
        }
    }


    tooltipDiv.html(tooltipContent)
        .style("left", `${e.pageX + 20}px`)
        .style("top", `${e.pageY + 20}px`);

}

export function hideTooltip(tooltipDiv) {
    tooltipDiv.transition()
        .duration(500)
        .style("opacity", 0);
}

/**
 *  Find the path total length
 *
 * @param {SVGPathElement} path
 * @returns {Number} Path's total length in pixels
 */
export function pathTotalLength(path) {
    return path.getTotalLength();
}

/**
 * Get the x,y point position at a particular length on the path
 *
 * @param {SVGPathElement} path
 * @param {Number} dist Distance within the path's total length
 * @returns {Object} x,y coordinates of the point
 */
export function getPoint(path, dist) {
    return path.getPointAtLength(dist);
}

export function getTextWidth(text, fontSize, fontFace) {
    var canvas = document.createElement('canvas');
    var context = canvas.getContext('2d');
    context.font = fontSize + 'px ' + fontFace;
    return context.measureText(text);
}

export function wrapTextByWidth(zeText, width, fontSize = 10, fontFace = "sans-serif") {

    // fontSize doesn't need a unit - getTextWidth adds "px" automatically

    var outputArray = [];

    var words = zeText.split(/\s+/).reverse();
    var word;
    var line = [];
    var lastline;
    var linewords;
    var lineNumber = 1;
    var linewords2;

    while (word = words.pop()) {
        line.push(word);
        linewords = line.join(" ");

        const { width: textWidth } = getTextWidth(linewords, fontSize, fontFace);

        if (textWidth > width) {

            if (lastline) {
                if (lastline.length == 1) {
                    outputArray.push(lastline[0]);
                } else {
                    outputArray.push(lastline);
                }
            }

            line = [word];
            linewords = [word];
            lineNumber++;

        }

        lastline = linewords;

    }


    if (linewords.length == 1) {
        outputArray.push(linewords[0]);
    } else {
        outputArray.push(linewords);
    }

    return outputArray;


};

export function degree2Radian(deg) {
    return deg * Math.PI / 180;
}

export function rad2deg(rad) {
    return rad * 180 / Math.PI;
}

export function rectangleInCircle(rx, ry, w, h, cx, cy, r, d) {
    let dx = Math.max(Math.abs(cx - rx), Math.abs(cx - (rx + w)));
    let dy = Math.max((cy - ry), (cy - (ry + h)));
    const bInCircle = (dx * dx + dy * dy) <= (r * r);
    // !bInCircle &&
    // console.log("rectangleInCircle", bInCircle, "dx", dx, "dy", dy, w, h, r, d)
    return bInCircle;
}

export function moveRectangleInCircle(rx, ry, w, h, cx, cy, r) {
    let dx = Math.max((cx - rx), (cx - (rx + w)));
    let dy = Math.max((cy - ry), (cy - (ry + h)));
    const delta = 1;    // 5px
    let maxIterations = 500;
    while ((dx * dx + dy * dy) > (r * r) && --maxIterations > 0) {
        if (rx > cx) {
            rx-= delta;
        } else if (rx + w < cx) {
            rx+= delta;
        }
        if (ry > cy) {
            ry-=delta;
        } else if (ry + h < cy) {
            ry+=delta;
        }
        dx = Math.max((cx - rx), (cx - (rx + w)));
        dy = Math.max((cy - ry), (cy - (ry + h)));

        // -0.8955295946962494 -19.16855970560769 189.69822040530374 19.16855970560769 36352.84850612664 34596

        // console.log("moving", rx, ry, dx, dy, (dx * dx + dy * dy), (r * r))
    }
    return [rx, ry];
}


// Place non-overlapping circles with a given radius r along the perimeter of a rectangle
export function placeCirclesOnRectangle(rectangle, numCircles, r) {
    let circles = [];
    let perimeter = 2 * (rectangle.w + rectangle.h);
    let spacing = perimeter / numCircles;

    for (let i = 0; i < numCircles; i++) {
        let distance = i * spacing;
        let cx, cy;

        if (distance < rectangle.w) {
            cx = rectangle.x + distance;
            cy = rectangle.y;
        } else if (distance < rectangle.w + rectangle.h) {
            cx = rectangle.x + rectangle.w;
            cy = rectangle.y + distance - rectangle.w;
        } else if (distance < 2 * rectangle.w + rectangle.h) {
            cx = rectangle.x + 2 * rectangle.w + rectangle.h - distance;
            cy = rectangle.y + rectangle.h;
        } else {
            cx = rectangle.x;
            cy = rectangle.y + 2 * (rectangle.w + rectangle.h) - distance;
        }

        circles.push({ cx: cx, cy: cy, r: r });
    }

    return circles;
}

// export function moveRectangleInCircleMatter() {

//     // Create a new physics engine
//     const engine = Engine.create();

//     // Define the circle and rectangles
//     const circle = { cx: 300, cy: 300, r: 250 };
//     const rectangles = [
//         { x: 100, y: 100, w: 50, h: 50 },
//         { x: 200, y: 200, w: 60, h: 40 },
//         { x: 300, y: 300, w: 70, h: 30 }
//     ];

//     // Create Matter.js bodies for the circle and rectangles
//     const circleBody = Bodies.circle(circle.cx, circle.cy, circle.r);
//     const rectangleBodies = rectangles.map(rect => Bodies.rectangle(rect.x + rect.w / 2, rect.y + rect.h / 2, rect.w, rect.h));

//     // Add the bodies to the physics world
//     World.add(engine.world, [circleBody].concat(rectangleBodies));

//     // Run the physics simulation for a fixed number of steps
//     for (let i = 0; i < 1000; i++) {
//         Engine.update(engine);

//         // Apply forces to keep the rectangles within the circle
//         rectangleBodies.forEach(body => {
//             let dx = body.position.x - circle.cx;
//             let dy = body.position.y - circle.cy;
//             let d = Math.sqrt(dx * dx + dy * dy);
//             if (d > circle.r) {
//                 Body.applyForce(body, body.position, {
//                     x: -dx / d * 0.001,
//                     y: -dy / d * 0.001
//                 });
//             }
//         });
//     }

//     // Get the final positions of the rectangles
//     let finalRectangles = rectangleBodies.map(body => ({
//         x: body.position.x - body.bounds.max.x + body.bounds.min.x,
//         y: body.position.y - body.bounds.max.y + body.bounds.min.y,
//         w: body.bounds.max.x - body.bounds.min.x,
//         h: body.bounds.max.y - body.bounds.min.y
//     }));

//     console.log(finalRectangles);
// }
// moveRectangleInCircleMatter()