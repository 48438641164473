import {
    select,
    create,
    pie,
    arc,
    line,
    forceManyBody,
    forceCollide,
    forceCenter,
    forceLink,
    forceSimulation,
    forceX,
    forceY,
    bin,
    groups,
    group,
    rollup,
    ascending,
    scaleLinear,
    range
} from "d3";

import { getTextWidth, moveRectangleInCircle, rectangleInCircle, wrapTextByWidth, rad2deg, placeCirclesOnRectangle } from "./common";

export function Chart(el, data, {
    marginTop = 10,
    marginRight = 10,
    marginBottom = 10,
    marginLeft = 10,
    paddingHorizontal = 120,   // horizontal padding
    paddingVertical = 100,  // vertical padding
    themeLabelWidth = 100,  // to accomodate labels around the circle 
    width = 600,
    height = 600,
    circleBorderWidth = 12, // stroke width of the main circle
    themeCircleRadius = 20,
    trendRadius = 10,
    fontSize = 16,
    ...options
} = {}) {

    // ensure element exists
    if (!select(el).node()) {
        throw Error("No container element provided.");
    }

    // dimensions
    const bct = select(el).node().getBoundingClientRect();

    const minSize = Math.min(bct.width, bct.height);
    // is mobile?
    const isMobile = minSize < 600;
    // is small device? Should we do mobile form rendering?
    const isSmallDevice = isMobile;

    // For mobiles, consider the max instead of min of w & h
    // const svgWidth = isMobile ? Math.max(bct.width || width, bct.height || height) : (bct.width || width);
    // const svgHeight = isMobile ? svgWidth : (bct.height || height);
    const svgWidth = bct.width || width;
    const svgHeight = bct.height || height;

    // reduce padding for mobile devices
    if (isMobile) {
        paddingHorizontal = 80;
        paddingVertical = 80;
    }
    if (minSize < 750) {
        paddingHorizontal = 140;
        if (minSize > 600) {
            paddingHorizontal = 140;
        } else if (minSize > 450) {
            paddingHorizontal = 110;
        } else {
            paddingHorizontal = 95;
        }
    }

    const w = svgWidth - paddingHorizontal * 2 - marginLeft - marginRight;
    const h = svgHeight - paddingVertical * 2 - marginTop - marginBottom;

    // max diameter possible
    const diameter = Math.max(Math.min(w, h), 100);
    let radius = diameter / 2;

    let themeTextFontSize = 12;

    // adjust strength based on diameter size
    let manyBodyStrength = -120;

    // radius adjustment for mobile devices
    let mobileAdjustment = 0;

    if (diameter > 1200) {
        manyBodyStrength = -100;
    } else if (diameter < 500) {
        manyBodyStrength = 0;
    } else if (diameter < 600) {
        manyBodyStrength = -80;
    } else if (diameter < 900) {
        manyBodyStrength = -150;
    }

    // update dimensions for mobile
    if (isMobile) {
        themeCircleRadius = Math.min(themeCircleRadius, (2 * Math.PI * radius / data.THEMES.length) / 2.85);
        fontSize *= 0.65;
        circleBorderWidth *= 0.8;
        manyBodyStrength = -50;
        mobileAdjustment = -8;

        // smaller devices
        if (diameter < 200) {
            themeTextFontSize = 6.5;
            fontSize = 8;
            circleBorderWidth = 7;
            trendRadius = 4.5;
            themeCircleRadius -= 2;
            manyBodyStrength = -10;
            radius = radius - 5;
        }
        if (diameter <= 100) { 
            themeTextFontSize = 2.5;
        }

        if (diameter >= 200 && diameter < 350) {
            themeTextFontSize = 8;
            mobileAdjustment = -15;
        }

    }

    const innerRadius = radius - circleBorderWidth;
    // center radius of labels
    const labelRadius = radius + mobileAdjustment + circleBorderWidth / 2 + themeCircleRadius * 2.5;

    // inner radius to hold the trend rectangle labels
    const labelInnerRadius = innerRadius;

    // interconnection point radius
    const interconnectionPointRadius = 3;

    console.log("isMobile", isMobile, svgWidth, svgHeight, "w", w, "h", h, "diameter", diameter);

    // Data
    const { THEMES, TRENDS, INTERCONNECTIONS } = data;
    // ./

    // Construct arcs.
    const themeArcs = pie().padAngle(0).sort(null).value(1)(THEMES);
    const arcGenerator = arc().innerRadius(innerRadius).outerRadius(radius);
    const arcLabelGenerator = arc().innerRadius(labelRadius).outerRadius(labelRadius);

    // ./

    // create svg
    const svg = create("svg")
        .classed("interconnections-chart ic", true)
        .attr("width", svgWidth)
        .attr("height", svgHeight)
        .attr("viewBox", [-svgWidth / 2, -svgHeight / 2, svgWidth, svgHeight])
        .attr("style", `max-width: 100%; height: 100%; font-size:${fontSize}px`);

    // add defs
    svg.append("defs")
        .html(`<linearGradient id="mainGradient" gradientTransform="rotate(0)">
        <stop offset="5%" stop-color="rgb(29,106,154)"  />
        <stop offset="25%" stop-color="rgb(138,115,121)" />
        <stop offset="50%" stop-color="#f0813a" />
        <stop offset="65%" stop-color="rgb(202,136,102)" />
        <stop offset="95%" stop-color="#4b9f61" />
      </linearGradient>`);

    // Group main
    const gMain = svg.append("g")
        .classed("g-main", true);

    // add a clip path for shapes inside the circle 
    // so that they cut-off outside the circle
    svg.append("defs")
        .append("clipPath")
        .attr("id", "clip-ic-main-circle")
        .append("circle")
        .attr("r", radius)
        .attr("cx", 0)
        .attr("cy", 0);

    // Group for interconnection points on trend labels
    const gInterconnectionLines = gMain.append("g").classed("g-ic-lines", true)
        .attr("clip-path", "url(#clip-ic-main-circle)");

    // Group for trend labels
    const gLabels = gMain.append("g").classed("g-labels", true)
        .attr("clip-path", "url(#clip-ic-main-circle)");

    // Group for interconnection points on trend labels
    const gInterconnectionPoints = gMain.append("g").classed("g-ic-points", true)
        .attr("clip-path", "url(#clip-ic-main-circle)");

    // Group for shapes
    const gShapes = gMain.append("g").classed("g-shapes", true);

    // for active trend text in small devices
    const gActiveTrend = gShapes.append("g")
        .classed("g-active-trend", true)
        .attr("text-anchor", "middle")
        .attr("dominant-baseline", "central")
        .attr("transform", `translate(0, ${-labelRadius - paddingVertical / 1.5})`);

    // Draw Shapes
    //

    // main circle
    const circle = gShapes.append("circle")
        .classed("ic-main-circle", true)
        .attr("r", radius)
        .attr("stroke", "url(#mainGradient)")
        .attr("stroke-width", circleBorderWidth);


    // themes circles
    //
    const gThemesCircle = gShapes.selectAll(".g-theme-circle")
        .data(themeArcs)
        .join("g")
        .classed("g-theme-circle", true)
        .attr("transform", d => {
            const cd = arcGenerator.centroid(d);
            const dx = (d.startAngle > Math.PI ? -1 : 1) * circleBorderWidth / 2;
            const midAngle = d.startAngle + ((d.endAngle - d.startAngle) / 2);
            const midAngleDeg = rad2deg(midAngle);
            // top half - push up, otherwise push down
            const dy = (midAngleDeg >= 260 || midAngleDeg <= 100 ? -1 : 1) * circleBorderWidth / 2;

            // add to datum
            d.cx = cd[0] + dx;
            d.cy = cd[1] + dy;

            return `translate(${[d.cx, d.cy]})`;
        })
        .style("cursor", "pointer");

    // add cirlce
    gThemesCircle.append("circle")
        .classed("theme-circle", true)
        .attr("fill", d => d.data.color)
        .attr("r", themeCircleRadius);

    // theme id text
    gThemesCircle.append("text")
        .classed("theme-circle-text", true)
        .attr("fill", "#fff")
        .attr("text-anchor", "middle")
        .attr("dominant-baseline", "central")
        .text(d => d.data.id);

    // bind click
    gThemesCircle.on("click", handleThemeClick);

    // ./

    // theme name text
    //
    const gThemesText = gShapes.selectAll(".g-theme-text")
        .data(themeArcs)
        .join("g")
        .classed("g-theme-text", true)
        .style("cursor", "pointer")
        // .attr("transform", d => `translate(${arcLabelGenerator.centroid(d)})`)
        .attr("transform", d => {
            const [x, y] = arcLabelGenerator.centroid(d);
            const midAngle = d.startAngle + ((d.endAngle - d.startAngle) / 2);
            const midAngleDeg = rad2deg(midAngle);
            const rightSide = midAngleDeg > 45 && midAngleDeg < 135;
            const leftSide = midAngleDeg > 225 && midAngleDeg < 315;
            const topSide = midAngleDeg >= 315 || midAngleDeg < 45;
            const bottomSide = midAngleDeg > 135 && midAngleDeg < 225;
            const dy = (bottomSide ? 1 : topSide ? -1 : 0) * themeCircleRadius * 0.5;
            const dx = (rightSide ? 1 : leftSide ? -1 : 0) * themeCircleRadius * 2;
            return `translate(${[x + dx, y + dy]})`;
        })
        .attr("font-size", themeTextFontSize)
        .attr("dominant-baseline", "central");

    gThemesText.append("text")
        // .attr("text-anchor", d => {
        //     // const midAngle = d.startAngle + ((d.endAngle - d.startAngle) / 2);
        //     // const midAngleDeg = rad2deg(midAngle);
        //     // if (midAngleDeg > 45 && midAngleDeg < 115) {
        //     //     return "end";
        //     // }
        //     // if (midAngleDeg > 215 && midAngleDeg < 300) {
        //     //     return "middle";
        //     // }
        //     if (d.startAngle < Math.PI && d.endAngle > Math.PI) {
        //         return "middle";
        //     }
        //     return d.startAngle > Math.PI ? "end" : "middle";
        // })
        .selectAll("tspan")
        .data(d => {
            const lines = wrapTextByWidth(d.data.theme, themeLabelWidth);
            return lines.map(l => ({ text: l, total: lines.length }));
        })
        .join("tspan")
        .attr("text-anchor", "middle")
        .attr("x", 0)
        .attr("y", (_, i) => `${((-_.total + 1) / 2 + i) * 1.15}em`)    // center position the tspans
        .text((d) => d.text);

    gThemesText.on("click", handleThemeClick);

    // create a custom sort order of theme arcs as follows:
    //  1. Themes in angle > 260 and < 370
    //  2. Then starting from 0 to 260.
    const aLastQtrThemes = [];
    const aOtherQtrThemes = [];
    themeArcs.forEach(d => {
        const midAngle = d.startAngle + ((d.endAngle - d.startAngle) / 2);
        const midAngleDeg = rad2deg(midAngle);
        if (midAngleDeg >= 260 && midAngleDeg <= 370) {
            aLastQtrThemes.push(d.data.theme);
        } else {
            aOtherQtrThemes.push(d.data.theme);
        }
    });
    const aCustomSortedThemes = [...aLastQtrThemes, ...aOtherQtrThemes];

    // Add trend labels
    //
    // font scale
    const fScale = scaleLinear().domain([500, 1500]).range([12, 25]).clamp(true);
    const trendFontSize = isSmallDevice ? fontSize : fScale(diameter);
    const fontFamily = getComputedStyle(select(el).node()).fontFamily;
    const trendNodes = TRENDS.map((t, i) => ({
        id: `id-${i}`,
        trendIndex: i,
        trend: t,
        // to have a background for the trend text
        rectWidth: 10 + getTextWidth(t, trendFontSize, fontFamily).width,
        rectHeight: trendFontSize * 1.5,
        // in case of circle, its radius
        r: trendRadius,
        // positions
        x: 0,
        y: 0
    }));

    // radius scale
    const rScale = scaleLinear()
        .domain([500, 1500])
        .range([40, 120])
        .clamp(true);

    // Construct the forces.
    const simulation = forceSimulation(trendNodes)
        .force('charge', forceManyBody().strength(manyBodyStrength))
        .force('center', forceCenter(0, 0))
        .force('collision', forceCollide().radius(isMobile ? trendRadius : rScale(diameter)))
        .force("x", forceX(0))
        .force("y", forceY(0))
        // .on("tick", ticked)
        .stop();

    // .on("end", () => {
    //     console.log("move rectangles")
    //     trendNodes.forEach((d, i) => {
    //         const { x, y, rectHeight, rectWidth } = d;
    //         // check if rect outside the circle
    //         const bInCircle = rectangleInCircle(x, y, rectWidth, rectHeight, 0, 0, radius);
    //         if (!bInCircle) {
    //             const [rx, ry] = moveRectangleInCircle(x, y, rectWidth, rectHeight, 0, 0, radius);
    //             d.x = rx;
    //             d.y = ry;
    //             console.log(i, d);
    //         }
    //     });
    //     ticked();
    // });

    // Run the simulation for a fixed number of steps
    function runSimulation(numTicks = 200) {
        for (let i = 0; i < numTicks; i++) {
            simulation.tick();
        }
    }

    runSimulation(200);

    if (!isSmallDevice) {

        // Move any labels which are outside the circle 
        // to be within the circle
        trendNodes.forEach((d, i) => {
            const { x, y, rectHeight, rectWidth } = d;
            // check if rect outside the circle
            const bInCircle = rectangleInCircle(x, y, rectWidth, rectHeight, 0, 0, labelInnerRadius, d);
            if (!bInCircle) {
                const [rx, ry] = moveRectangleInCircle(x, y, rectWidth, rectHeight, 0, 0, labelInnerRadius);
                d.x = rx;
                d.y = ry;
                // console.log(i, x, y, rx, ry, d);
            }
        });

        // run again
        runSimulation();

    }

    // Add trends
    //
    const gTrends = gLabels.selectAll(".g-trend")
        .data(trendNodes)
        .join("g")
        .classed("g-trend", true)
        .attr("font-size", trendFontSize)
        .attr("text-anchor", "middle");
        // .attr("dominant-baseline", "central");

    if (isSmallDevice) {
        gTrends.append("circle")
            .attr("r", d => d.r)
            .attr("fill", "#dedede")
            .attr("fill-opacity", 0.975)
            .attr("fill", d => `url(#${d.id})`)
            .attr("stroke-width", 0);
    } else {

        gTrends.append("rect")
            .attr("rx", interconnectionPointRadius)
            .attr("ry", interconnectionPointRadius)
            .attr("x", ({ rectWidth }) => -rectWidth / 2)
            .attr("width", ({ rectWidth }) => rectWidth)
            .attr("height", ({ rectHeight }) => rectHeight)
            .attr("fill", "#dedede")
            .attr("fill-opacity", 0.975)
            .attr("stroke", d => `url(#${d.id})`)
            .attr("stroke-width", .5);

        gTrends.append("text")
            // .attr("dy", ({ rectHeight }) => rectHeight / 2)
            .attr("dy", ({ rectHeight }) => "1em")
            .text(d => d.trend);

    }

    gTrends.append("title")
        .text(d => d.trend);

    // attache click on trends
    gTrends.on("click", handleTrendClick);

    // Add interconnections
    //

    // group interconnections by trend
    const aTrends = groups(INTERCONNECTIONS, d => d.trend);
    const oTrends = group(INTERCONNECTIONS, d => d.trend);
    const oThemeTrends = rollup(INTERCONNECTIONS, v => v.map(d => d.trend), d => d.theme);

    // make a map of theme data points
    const oThemes = new Map(themeArcs.map(t => [t.data.theme, t]));

    const aTrendLandingPoints = [];

    aTrends.forEach(([trend, aConnections], i) => {
        // get the trend data point
        const index = TRENDS.indexOf(trend);
        const oTrend = trendNodes[index];
        const { x, y, rectHeight: h, rectWidth: w } = oTrend;

        // get landing points
        let aPoints = [];

        if (isSmallDevice) {
            aPoints = range(aConnections.length).map((i) => {
                return {
                    ...aConnections[i],
                    cx: x,
                    cy: y
                }
            });
        } else {
            aPoints = placeCirclesOnRectangle({ x: x - w / 2, y, w, h }, aConnections.length, interconnectionPointRadius).map((p, i) => {
                return {
                    ...aConnections[i],
                    ...p,
                }
            });
        }

        // find out custom sorted order of the theme of the points
        const aIndexInCustomSort = aPoints.map(p => {
            return aCustomSortedThemes.indexOf(p.theme);
        })
        // sort the points
        aIndexInCustomSort.sort((a, b) => ascending(a, b));

        // reassign the themes
        aPoints.forEach((p, i) => {
            p.theme = aCustomSortedThemes[aIndexInCustomSort[i]];
            p.color = oThemes.get(p.theme).data.color;
        });

        // update aConnections
        aTrends[i][1] = aPoints;

        aTrendLandingPoints.push.apply(aTrendLandingPoints, aPoints);
    });

    // add gradient to trend border
    const defGradient = svg.append("defs");

    // build a gradient for the trend:
    // split equal stops for each theme its connected to
    defGradient.selectAll("linearGradient")
        .data(aTrends)
        .join("linearGradient")
        .attr("id", ([trend]) => {
            const index = TRENDS.indexOf(trend);
            const oTrend = trendNodes[index];
            return oTrend.id;
        })
        .selectAll("stop")
        .data(([, aConnections]) => {
            return aConnections.map((p, i) => ({
                offset: i * 100 / aConnections.length,
                color: p.color
            }))
        }).join("stop")
        .attr("offset", d => `${d.offset}%`)
        .attr("stop-color", d => d.color);

    // console.log("aTrendLandingPoints", aTrendLandingPoints)

    gInterconnectionPoints.selectAll(".ic-point")
        .data(aTrendLandingPoints)
        .join("circle")
        .classed("ic-point", true)
        .attr("cx", d => d.cx)
        .attr("cy", d => d.cy)
        .attr("r", d => d.r)
        .attr("fill", d => d.color);

    // Add interconnection lines
    //

    gInterconnectionLines.selectAll(".ic-line")
        .data(aTrendLandingPoints)
        .join("line")
        .classed("ic-line", true)
        .attr("x1", d => {
            // get theme
            const t = oThemes.get(d.theme);
            return t.cx;
        })
        .attr("y1", d => {
            // get theme
            const t = oThemes.get(d.theme);
            return t.cy;
        })
        .attr("x2", d => d.cx)
        .attr("y2", d => d.cy)
        .attr("stroke-width", 1)
        .attr("stroke", d => d.color);


    function ticked() {
        gTrends.attr("transform", d => {
            return `translate(${[d.x, d.y]})`
        });
    }

    ticked();

    select(el).on("click", resetHighlight);

    // render the chart
    select(el).select("*").remove();
    select(el).node().appendChild(svg.node());


    function resetHighlight() {
        const container = select(el);
        container.selectAll(".unhighlight").classed("unhighlight", false);
        container.selectAll(".highlight").classed("highlight", false);
        gActiveTrend.selectAll("*").remove();
    }

    // toggle highlight on double click on the same trigger
    let lastTriggerID;
    function toggleHighlight(triggerID) {
        if (lastTriggerID == triggerID) {
            resetHighlight();
            lastTriggerID = null;
        } else {
            lastTriggerID = triggerID;
        }
    }

    function showTrendText(ev, d) {
        if (!d) {
            gActiveTrend.selectAll("*").remove();
            return;
        }

        gActiveTrend.selectAll("rect")
            .data([d])
            .join("rect")
            .attr("rx", interconnectionPointRadius)
            .attr("ry", interconnectionPointRadius)
            .attr("x", ({ rectWidth }) => -rectWidth / 2)
            .attr("width", ({ rectWidth }) => rectWidth)
            .attr("height", ({ rectHeight }) => rectHeight)
            .attr("fill", "#dedede")
            .attr("fill-opacity", 0.975)
            .attr("stroke", d => `url(#${d.id})`)
            .attr("stroke-width", 1);

        gActiveTrend.selectAll("text.t-trend")
            .data([d])
            .join("text")
            .classed("t-trend", true)
            .attr("x", 0)
            .attr("dy", ({ rectHeight }) => rectHeight / 2)
            .text(d => d.trend);
    }

    function handleTrendClick(ev, d) {
        const { trend } = d;

        ev.stopPropagation();

        // un-highlight other trends
        gInterconnectionLines.selectAll(".ic-line")
            .classed("unhighlight", false)
            .classed("highlight", false)
            .filter(d => d.trend != trend)
            .classed("unhighlight", true);

        gInterconnectionLines.selectAll(".ic-line")
            .filter(d => d.trend == trend)
            .classed("highlight", true);

        gInterconnectionPoints.selectAll(".ic-point")
            .classed("unhighlight", false)
            .filter(d => d.trend != trend)
            .classed("unhighlight", true);

        gTrends
            .classed("unhighlight", false)
            .filter(d => d.trend != trend)
            .classed("unhighlight", true);

        // connected themes
        const aThemes = oTrends.get(trend).map(t => t.theme);

        gThemesCircle
            .classed("unhighlight", false)
            .filter(d => !aThemes.includes(d.data.theme))
            .classed("unhighlight", true);

        gThemesText
            .classed("unhighlight", false)
            .filter(d => !aThemes.includes(d.data.theme))
            .classed("unhighlight", true);

        toggleHighlight(trend);

        if (isSmallDevice) {
            showTrendText.bind(this)(ev, d);
        }

    }

    function handleThemeClick(ev, d) {
        const { data: { theme } } = d;

        ev.stopPropagation();

        // unhighlight other themes
        //
        gThemesCircle
            .classed("unhighlight", false)
            .filter(d => d.data.theme != theme)
            .classed("unhighlight", true);

        gThemesText
            .classed("unhighlight", false)
            .filter(d => d.data.theme != theme)
            .classed("unhighlight", true);

        // highlight all the trends which are connected to this theme or 
        // un-highlight other trends
        gInterconnectionLines.selectAll(".ic-line")
            .classed("unhighlight", false)
            .classed("highlight", false)
            .filter(d => d.theme != theme)
            .classed("unhighlight", true);

        gInterconnectionLines.selectAll(".ic-line")
            .filter(d => d.theme == theme)
            .classed("highlight", true);

        gInterconnectionPoints.selectAll(".ic-point")
            .classed("unhighlight", false)
            .filter(d => d.theme != theme)
            .classed("unhighlight", true);

        // get trends connected to this theme
        const aTrends = oThemeTrends.get(theme);
        gTrends
            .classed("unhighlight", false)
            .filter(d => !aTrends.includes(d.trend))
            .classed("unhighlight", true);

        toggleHighlight(theme);

        if (isSmallDevice) {
            showTrendText.bind(this)(ev);
        }
    }
}