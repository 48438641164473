import "./style.css";

import { csv } from "d3";
import { Chart } from "./src/index";

// Read data file located in folder ./data/
import dataFile from "./data/interconnection.csv?url";

csv(dataFile).then(function (rows) {
    
    // Data Store
    const THEMES = [...(new Map(rows.map(({ id, theme, color }) => [id, { id, theme, color }]))).values()],
        TRENDS = [...(new Set(rows.map(({ trend }) => trend))).values()],
        INTERCONNECTIONS = rows;

    // console.log(THEMES, TRENDS, INTERCONNECTIONS);

    new Chart("#chart", {THEMES, TRENDS, INTERCONNECTIONS}, {
        circleBorderWidth: 12   // pixels
    });


});